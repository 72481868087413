import CURRENT_USER_QUERY from '~/apollo/queries/client/currentUser'
import { setUser } from '~/apollo/state'

const fetchCurrentUserMixin = {
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      update(data) {
        setUser(data.currentUser)
      },
      error(_error) {},
    },
  },
}

export default fetchCurrentUserMixin
