// import { SecureStorage } from '@ionic-native/secure-storage'
import { HTTP } from '@ionic-native/http'

const PLATFORM_IOS = 'ios'
const PLATFORM_ANDROID = 'android'

function isMobileApp() {
  return (
    process.env.CAPACITOR &&
    (process.env.CAPACITOR === true || process.env.CAPACITOR === 'true')
  )
}

function getPlatform() {
  if (!isMobileApp()) {
    return 'web'
  }
  const { Capacitor } = require('@capacitor/core')
  return Capacitor.getPlatform()
}

function getDeviceInfo() {
  const { Device } = require('@capacitor/device')
  return Device.getInfo()
}

function getSecureStorage() {
  // Temporary use common storage because of bug on Android - https://github.com/mibrito707/cordova-plugin-secure-storage-echo/issues/30
  return getStorage()
  // return SecureStorage.create('Werecover_storage')
}

function getStorage() {
  const { Storage } = require('@capacitor/storage')
  return {
    async get(key) {
      const { value } = await Storage.get({ key })
      return value
    },
    set(key, value) {
      return Storage.set({ key, value })
    },
    clear() {
      return Storage.clear()
    },
  }
}

function resetState() {
  if (!isMobileApp()) {
    return false
  }
  return getStorage().clear()
}

function getRedirectDestinationUrl(url) {
  if (!isMobileApp() || !HTTP) {
    return
  }
  const httpClient = HTTP
  httpClient.setFollowRedirect(false)
  httpClient.setRequestTimeout(5)
  return new Promise((resolve, reject) => {
    httpClient
      .get(url, {}, {})
      .then(() => reject(new Error('Expect redirect')))
      .catch((response) => {
        if (response.status === 302 && response.headers.location) {
          resolve(response.headers.location)
        } else {
          reject(response)
        }
      })
  })
}

async function getDeepLinkPath(url) {
  const withRedirect = process.env.REFERRER_HOSTS.split(',').some((host) =>
    url.includes(host),
  )
  if (withRedirect) {
    url = await getRedirectDestinationUrl(url)
  }
  return url.replace(/.*:\/\/[\w.-]*\//i, '/')
}

export {
  PLATFORM_IOS,
  PLATFORM_ANDROID,
  isMobileApp,
  getPlatform,
  getDeviceInfo,
  getSecureStorage,
  getStorage,
  resetState,
  getRedirectDestinationUrl,
  getDeepLinkPath,
}
