import Cookies from 'js-cookie'
import { logout as resetState } from '~/apollo/state'
import { client, purgeCache } from '~/apollo'
import {
  resetState as resetMobileAppState,
  isMobileApp,
} from '~/helpers/mobile-app'
import LOGOUT_MUTATION from '~/apollo/mutations/user/logout'

export default async (_, { keepDeviceActive, reason }, { cache }) => {
  // if mobile app we need to reset the device
  if (isMobileApp() && !keepDeviceActive && reason !== 'unauthorized') {
    const { Device } = require('@capacitor/device')
    if (Device && Device.getId) {
      try {
        const id = await Device.getId()
        await client.mutate({
          mutation: LOGOUT_MUTATION,
          variables: {
            input: {
              deviceUuid: id.uuid,
            },
          },
        })
      } catch (error) {
        if (!error.toString().includes('Not logged in as a recognized user')) {
          console.error(error)
        }
      }
    }
  }
  await resetMobileAppState()
  await purgeCache()
  await resetState()
  localStorage.removeItem('wr-user-token')
  Cookies.remove(`wr-user-${process.env.APP_ENV}`, {
    domain: process.env.DOMAIN,
  })
}
