import client from '~/apollo'
import SET_CURRENT_PROVIDER_MUTATION from '~/apollo/mutations/client/setCurrentProvider'
import SET_CURRENT_USER_MUTATION from '~/apollo/mutations/client/setCurrentUser'
import SET_TOKEN_MUTATION from '~/apollo/mutations/client/setToken'
import {
  AUTH_TYPE_BIOMETRIC,
  AUTH_TYPE_CREDENTIALS,
  AUTH_TYPE_TOKEN,
} from '~/helpers/login'
import {
  isMobileApp,
  lockApp as lockMobileApp,
  unlockApp as unlockMobileApp,
} from '~/helpers/mobile-app'
import { init as initVoip } from '~/helpers/voip'

export default async (_, { token, expiresAt, user, authType, twilioToken }) => {
  if (isMobileApp()) {
    if (authType === AUTH_TYPE_TOKEN) {
      await lockMobileApp()
    } else if (
      authType === AUTH_TYPE_CREDENTIALS ||
      authType === AUTH_TYPE_BIOMETRIC
    ) {
      await unlockMobileApp()
    }
  }

  if (!user.roles.includes('ADMIN') && twilioToken) {
    initVoip(twilioToken)
  }

  if (token) {
    // this would be there if the server was returning the token properly
    const setToken = {
      __typename: 'Token',
      jwt: token,
      expiresAt,
    }
    await client.mutate({
      mutation: SET_TOKEN_MUTATION,
      variables: { token: setToken },
    })
  }

  await client.mutate({
    mutation: SET_CURRENT_USER_MUTATION,
    variables: { user },
  })

  if (user.activeProviderOrganization) {
    const organizationProviders = user.providers.filter(
      (provider) =>
        provider.organization.id === user.activeProviderOrganization.id,
    )
    const selectedProviderId = localStorage.getItem('selectedProvider')
    let activeProvider = null
    if (selectedProviderId) {
      activeProvider = user.providers.find(
        (provider) => provider.id === parseInt(selectedProviderId),
      )
    }
    await client.mutate({
      mutation: SET_CURRENT_PROVIDER_MUTATION,
      variables: { provider: activeProvider || organizationProviders[0] },
    })
  }
}
