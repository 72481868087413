import gql from 'graphql-tag'

export const typeDefs = gql`
  type Contact {
    id: Int!
    name: String!
    firstName: String!
    lastName: String!
    phoneNumber: String
    email: String
  }

  extend type Eligibility {
    subscriberFullName: String
    insuranceTypeUppercase: String
    benefitsBeginOnFormatted: String
    benefitsEndOnFormatted: String
    policyActive: String
    deductibleIndividualInNetworkAppliedFormatted: String
    deductibleIndividualInNetworkLimitFormatted: String
    deductibleIndividualOutOfNetworkAppliedFormatted: String
    deductibleIndividualOutOfNetworkLimitFormatted: String
    outOfPocketIndividualInNetworkAppliedFormatted: String
    outOfPocketIndividualInNetworkLimitFormatted: String
    outOfPocketIndividualOutOfNetworkAppliedFormatted: String
    outOfPocketIndividualOutOfNetworkLimitFormatted: String
    deductibleFamilyInNetworkAppliedFormatted: String
    deductibleFamilyInNetworkLimitFormatted: String
    deductibleFamilyOutOfNetworkAppliedFormatted: String
    deductibleFamilyOutOfNetworkLimitFormatted: String
    outOfPocketFamilyInNetworkAppliedFormatted: String
    outOfPocketFamilyInNetworkLimitFormatted: String
    outOfPocketFamilyOutOfNetworkAppliedFormatted: String
    outOfPocketFamilyOutOfNetworkLimitFormatted: String
  }

  extend type InsurancePolicy {
    address: String
    holderName: String
    insuranceCompanyName: String
    planTypeUppercase: String
  }

  extend type Patient {
    advocateName: String
    birthdateOnFormatted: String
    budgetFormatted: String
    contactFirstName: String
    contactName: String
    fullName: String
    genderFormatted: String
    location: String
    paymentMethodFormatted: String
    phoneNumber: String
    seekingHelpFor: String
  }

  extend type EligibilityServiceCoverage {
    authorizationRequiredFormatted: String
    coinsurancePercentageFormatted: String
    copayAmountFormatted: String
    limitationAmountFormatted: String
    limitationInPlanNetworkFormatted: String
    serviceTypeFormatted: String
    timePeriodQualifierFormatted: String
  }

  extend type Provider {
    genderFormatted: String
    hasEnterprise: Boolean
    isPetFriendlyFormatted: String
    isClinician: Boolean
  }

  extend type ProviderPatientSettings {
    contactPhoneNumber: String
    getStatus: String
    hasConnected: Boolean
    statusDate: String
  }

  extend type User {
    billerNetworkId: String
    billerNetworkId: String
    billerOrganizationRole: String
    fullName: String
    getRole: String
    hasActiveSubscription: Boolean
    hasBillerNetwork: Boolean
    hasBillerNetwork: Boolean
    hasMultipleBillerOrganizations: Boolean
    hasMultipleBillerOrganizations: Boolean
    hasMultipleProviderOrganizations: Boolean
    hasMultipleProviderOrganizations: Boolean
    isPatient: Boolean
    providerOrganizationRole: String
  }

  extend type Vob {
    appliesToOutOfPocketMaxFormatted: String
    canOpBeBilledOnUbFormatted: String
    canOpBePerformedInFacilityFormatted: String
    claimsAddressFormatted: String
    claimsAuthorizationPhoneFormatted: String
    claimsAuthorizationSiteFormatted: String
    claimsGracePeriodFormatted: String
    claimsPayorIdFormatted: String
    claimsPolicyPaidThroughDateFormatted: String
    coverageAfterDeductibleMetFormatted: String
    coverageAfterOutOfPocketMetFormatted: String
    coverageExclusionsFormatted: String
    createdAtFormatted: String
    familyInNetworkDeductibleFormatted: String
    familyInNetworkDeductibleMetFormatted: String
    familyInNetworkMaxOutOfPocketFormatted: String
    familyInNetworkMaxOutOfPocketMetFormatted: String
    familyOutOfNetworkDeductibleFormatted: String
    familyOutOfNetworkDeductibleMetFormatted: String
    familyOutOfNetworkMaxOutOfPocketFormatted: String
    familyOutOfNetworkMaxOutOfPocketMetFormatted: String
    feeScheduleFormatted: String
    gracePeriodEndDateFormatted: String
    gracePeriodLengthFormatted: String
    individualInNetworkDeductibleFormatted: String
    individualInNetworkDeductibleMetFormatted: String
    individualInNetworkMaxOutOfPocketFormatted: String
    individualInNetworkMaxOutOfPocketMetFormatted: String
    individualOutOfNetworkDeductibleFormatted: String
    individualOutOfNetworkDeductibleMetFormatted: String
    individualOutOfNetworkMaxOutOfPocketFormatted: String
    individualOutOfNetworkMaxOutOfPocketMetFormatted: String
    isAffordableCareActPolicyFormatted: String
    isAssignmentOfBenefitsFormatted: String
    isBehavioralHealthCoverageFormatted: String
    isCalendarYearFormatted: String
    isCarveOutFormatted: String
    isClientInGracePeriodFormatted: String
    isCobraFormatted: String
    isComplete: Boolean
    isCoordinationOfBenefitsFormatted: String
    isFreeStandingFacilityCoveredFormatted: String
    isInAndOutOfNetworkBenefitsCombinedFormatted: String
    isInitialAssessmentCoveredFormatted: String
    isIopConsideredAsInpatientFormatted: String
    isLegacyPolicyFormatted: String
    isMedicaidFundedPolicyFormatted: String
    isMedicareFundedPolicyFormatted: String
    isMentalHealthAndSubstanceAbuseBenefitsCombinedFormatted: String
    isMonthToMonthPolicyFormatted: String
    isOutOfNetworkBenefitsFormatted: String
    isOutOfPocketAppliesToBehavioralHealthFormatted: String
    isOutOfStateBenefitsFormatted: String
    isPhpConsideredAsInpatientFormatted: String
    isPlanYearFormatted: String
    isPolicyActiveFormatted: String
    isPreExistingClauseFormatted: String
    isPrimaryPolicyFormatted: String
    isReferralsRequiredFormatted: String
    isSecondaryPolicyFormatted: String
    isSingleCaseAgreementsFormatted: String
    isWithin24hOfAdmissionFormatted: String
    labsHaveDailyAndYearlyMaxFormatted: String
    labsHaveDifferentDeductibleAndOutOfPocketFormatted: String
    notesForTreatmentCentersFormatted: String
    penaltyForFailingAcquirePreAuthorizationFormatted: String
    policyClientBirthdayFormatted: String
    policyCoverageEndDateFormatted: String
    policyCoverageStartDateFormatted: String
    policyEmployerNameFormatted: String
    policyGroupNumberFormatted: String
    policyHolderBirthdateFormatted: String
    policyHolderNameFormatted: String
    policyInsuranceCompanyFormatted: String
    policyInsuranceCompanyPhoneFormatted: String
    policyMemberNumberFormatted: String
    policyPlanNameFormatted: String
    policyPlanTypeFormatted: String
    policyTypeFormatted: String
    preAuthorizationRequiredFormatted: String
    recentReimbursementRatesFormatted: String
  }

  extend type WrVOB {
    dependentFullName: String
    dependentFirstNameFormatted: String
    dependentLastNameFormatted: String
    dependentDobFormatted: String
    subscriberFirstNameFormatted: String
    subscriberLastNameFormatted: String
    subscriberDobFormatted: String
    subscriberNumberFormatted: String
    planNumberFormatted: String
    planFundingFormatted: String
    planNameFormatted: String
    groupNumberFormatted: String
    planCategoryFormatted: String
    groupNameFormatted: String
    planTypeFormatted: String
    eligibilityBeginOnFormatted: String
    planBeginOnFormatted: String
    planEndOnFormatted: String
    individualInNetworkDeductibleFormatted: String
    individualInNetworkDeductibleMetFormatted: String
    individualInNetworkMaxOutOfPocketFormatted: String
    individualInNetworkMaxOutOfPocketMetFormatted: String
    familyInNetworkDeductibleFormatted: String
    familyInNetworkDeductibleMetFormatted: String
    familyInNetworkMaxOutOfPocketFormatted: String
    familyInNetworkMaxOutOfPocketMetFormatted: String
    individualOutOfNetworkDeductibleFormatted: String
    individualOutOfNetworkDeductibleMetFormatted: String
    individualOutOfNetworkMaxOutOfPocketFormatted: String
    individualOutOfNetworkMaxOutOfPocketMetFormatted: String
    familyOutOfNetworkDeductibleFormatted: String
    familyOutOfNetworkDeductibleMetFormatted: String
    familyOutOfNetworkMaxOutOfPocketFormatted: String
    familyOutOfNetworkMaxOutOfPocketMetFormatted: String
    planOnFormatted: String
  }

  extend type WrVobCoverageBenefit {
    inNetworkCoinsuranceFormatted: String
    inNetworkCopaymentFormatted: String
    inNetworkDailyMaxFormatted: String
    inNetworkYearlyMaxFormatted: String
    outOfNetworkCoinsuranceFormatted: String
    outOfNetworkCopaymentFormatted: String
    outOfNetworkDailyMaxFormatted: String
    outOfNetworkYearlyMaxFormatted: String
    individualInNetworkDeductibleFormatted: String
    individualInNetworkDeductibleMetFormatted: String
    individualInNetworkMaxOutOfPocketFormatted: String
    individualInNetworkMaxOutOfPocketMetFormatted: String
    familyInNetworkDeductibleFormatted: String
    familyInNetworkDeductibleMetFormatted: String
    familyInNetworkMaxOutOfPocketFormatted: String
    familyInNetworkMaxOutOfPocketMetFormatted: String
    individualOutOfNetworkDeductibleFormatted: String
    individualOutOfNetworkDeductibleMetFormatted: String
    individualOutOfNetworkMaxOutOfPocketFormatted: String
    individualOutOfNetworkMaxOutOfPocketMetFormatted: String
    familyOutOfNetworkDeductibleFormatted: String
    familyOutOfNetworkDeductibleMetFormatted: String
    familyOutOfNetworkMaxOutOfPocketFormatted: String
    familyOutOfNetworkMaxOutOfPocketMetFormatted: String
  }

  extend type Patient {
    hasUnreadMessages: Boolean
    unreadCount: Int
  }

  extend type Conversation {
    lastMessageCreatedAtFormatted: String
    unreadMessageCountFormatted: String
  }
`
