const ORGANIZATION_TYPES = {
  BILLER: 'BILLER',
  PROVIDER: 'PROVIDER',
}

export default {
  hasMultipleBillerOrganizations(user) {
    return user && user.organizationUsers
      ? user.organizationUsers.filter(
          (organizationUser) =>
            organizationUser.organization.type === ORGANIZATION_TYPES.BILLER,
        ).length > 1
      : false
  },
  hasMultipleProviderOrganizations(user) {
    return user && user.organizationUsers
      ? user.organizationUsers.filter(
          (organizationUser) =>
            organizationUser.organization.type === ORGANIZATION_TYPES.PROVIDER,
        ).length > 1
      : false
  },
  hasBillerNetwork(user) {
    return user &&
      user.activeProviderOrganization &&
      user.activeProviderOrganization.networks
      ? !!user.activeProviderOrganization.networks.find(
          (network) => network.type === 'BILLER',
        )
      : false
  },
  billerNetworkId(user) {
    return user &&
      user.activeBillerOrganization &&
      user.activeBillerOrganization.networks.length > 0
      ? user.activeBillerOrganization.networks[0].id
      : null
  },

  billerOrganizationRole(user) {
    if (!user || !user.activeBillerOrganization || !user.organizationUsers)
      return null

    const orgMatch = user.organizationUsers.find(
      (x) => x.organization.id === user.activeBillerOrganization.id,
    )
    return orgMatch && orgMatch.role
  },

  providerOrganizationRole(user) {
    if (!user || !user.activeProviderOrganization || !user.organizationUsers)
      return null

    const orgMatch = user.organizationUsers.find(
      (x) => x.organization.id === user.activeProviderOrganization.id,
    )
    return orgMatch && orgMatch.role
  },
  hasActiveSubscription(user) {
    if (!user.activeOrganization || !user.activeOrganization.subscriptions)
      return false
    return !!user.activeOrganization.subscriptions.find((x) => x.isActive)
  },
  getRole(user) {
    if (!user) {
      return null
    }
    if (user.roles.indexOf('PATIENT') !== -1) {
      return 'patient'
    }
    if (user.roles.indexOf('ADMIN') !== -1) {
      return 'admin'
    }
    if (user.roles.indexOf('ORG_STAFF') !== -1) {
      return 'org_staff'
    }
  },
  isPatient(user) {
    return user.roles.indexOf('PATIENT') !== -1
  },
  fullName(user) {
    if (!user) return
    return user.firstName + ' ' + user.lastName
  },
}
