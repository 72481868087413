import Bugsnag from '@bugsnag/js'
import { client } from '~/apollo'
import AUTHENTICATE_MUTATION from '~/apollo/mutations/authenticate'
import LOGIN_MUTATION from '~/apollo/mutations/client/login'
import LOGOUT_MUTATION from '~/apollo/mutations/client/logout'
import CLIENT_CURRENT_USER_QUERY from '~/apollo/queries/client/currentUser'

import Cookies from 'js-cookie'
import state, { getHomePath, toggleLoginModal } from '~/apollo/state'
import { AUTH_TYPE_TOKEN, canAccess, isPathProtected } from '~/helpers/login'

export default async function ({ store, req, route, redirect, app }) {
  const currentToken = JSON.parse(window.localStorage.getItem('wr-user-token'))

  const jwtDecode = require('jwt-decode')

  const nowTime = (new Date().getTime() / 1000) | 0

  if (currentToken) {
    const decoded = jwtDecode(currentToken.jwt)
    if (!decoded || decoded.exp < nowTime) {
      await client.mutate({
        mutation: LOGOUT_MUTATION,
        variables: {
          reason: 'unauthorized',
        },
      })
    }
  }

  let { currentUser } = client.readQuery({
    query: CLIENT_CURRENT_USER_QUERY,
  })

  if (currentToken && !currentUser) {
    try {
      const {
        data: { authenticate: data },
      } = await client.mutate({
        mutation: AUTHENTICATE_MUTATION,
        variables: { input: {} },
      })

      await client.mutate({
        mutation: LOGIN_MUTATION,
        variables: {
          ...data,
          authType: AUTH_TYPE_TOKEN,
        },
      })
      currentUser = data.user
    } catch (e) {
      console.error(e)
    }
  }

  if (currentUser) {
    Bugsnag.setUser(currentUser.id)
  }

  if (state.intendedUrl && currentUser) {
    const url = state.intendedUrl
    state.intendedUrl = null
    redirect(url)
  } else if (route.path === '/' && currentUser) {
    redirect(getHomePath({ currentUser }))
  }

  if (isPathProtected(route.path) && !canAccess(route.path, currentUser)) {
    state.intendedUrl = route.path
    if (currentUser) {
      redirect(getHomePath({ currentUser }))
    } else {
      if (process.env.CAPACITOR === 'true') {
        redirect('/')
        if (state.intendedUrl) {
          toggleLoginModal(true)
        }
      } else {
        redirect('/login')
      }
    }
  } else if (
    process.env.CAPACITOR === 'true' &&
    route.hash !== '#login' &&
    state.intendedUrl &&
    isPathProtected(state.intendedUrl) &&
    !canAccess(state.intendedUrl, currentUser)
  ) {
    redirect('/#login')
  }

  const allowedAppPaths = [
    '/book',
    '/centers',
    '/centers/claim',
    '/centers/onboard',
    '/contact-info',
    '/conversations',
    '/impersonate',
    '/insurance-info',
    '/login',
    '/map/accept-patient/',
    '/match-list',
    '/form',
    '/embedded-form',
    '/onboard',
    '/patient-info',
    '/schedule-info',
    '/sign',
    '/signup',
    '/token_auth',
  ]
  const pathIncludedInAllowed = allowedAppPaths.some(
    (item) => route.path.indexOf(item) !== -1,
  )
  if (
    !currentUser &&
    process.env.CAPACITOR !== 'true' &&
    !pathIncludedInAllowed &&
    process.env.APP_ENV !== 'development'
  ) {
    localStorage.clear()
    Cookies.remove(`wr-user-${process.env.APP_ENV}`, {
      domain: process.env.DOMAIN,
    })
  }
}
