import { paymentMethodOptions } from '~/helpers/paymentMethodsOrder'
import AWS_PRIVATE_QUERY from '~/apollo/queries/metadata/awsPrivate.gql'
import AWS_QUERY from '~/apollo/queries/metadata/aws'
import CONVERSATION_MESSAGE_ACTION_QUERY from '~/apollo/queries/metadata/enumConversationMessageAction'
import ENUM_CALENDAR_PROVIDERS_QUERY from '~/apollo/queries/metadata/enumCalendarProviders'
import ENUM_CLINICIAN_BILL_TYPE from '~/apollo/queries/metadata/enumClinicianBillType'
import ENUM_CONVERSATION_MESSAGE_MEDIA_TYPES_QUERY from '~/apollo/queries/metadata/enumConversationMessageMediaType.gql'
import ENUM_EXPORT_FORMAT_QUERY from '~/apollo/queries/metadata/enumExportFormat'
import ENUM_GENDER_TYPES_QUERY from '~/apollo/queries/metadata/enumGenderTypes'
import ENUM_INSURANCE_PLAN_TYPES_QUERY from '~/apollo/queries/metadata/enumInsurancePlanType'
import ENUM_NETWORK_TYPES_QUERY from '~/apollo/queries/metadata/enumNetworkTypes'
import ENUM_ORGANIZATION_MEMBERSHIP_TYPES_QUERY from '~/apollo/queries/metadata/enumOrganizationMembershipTypes'
import ENUM_ORGANIZATION_TYPES_QUERY from '~/apollo/queries/metadata/enumOrganizationTypes'
import ENUM_ORGANIZATION_USER_ROLE_TYPES_QUERY from '~/apollo/queries/metadata/enumOrganizationUserRole'
import ENUM_ORGANIZATION_USER_ROLES_QUERY from '~/apollo/queries/metadata/enumOrganizationUserRoles'
import ENUM_PATIENT_PAYMENT_METHOD from '~/apollo/queries/metadata/enumPatientPaymentMethod'
import ENUM_PATIENT_SOURCE_TYPES_QUERY from '~/apollo/queries/metadata/enumPatientSource'
import ENUM_PROVIDER_DOCUMENTS_STATUS from '~/apollo/queries/metadata/enumProviderDocumentstatus'
import ENUM_PROVIDER_GENDER_TYPES_QUERY from '~/apollo/queries/metadata/enumProviderGenderTypes'
import ENUM_PROVIDER_MASS_MESSAGE_PATIENT_GROUP_QUERY from '~/apollo/queries/metadata/enumProviderMassMessagePatientGroup'
import ENUM_PROVIDER_MASS_MESSAGE_STATUS_QUERY from '~/apollo/queries/metadata/enumProviderMassMessageStatus'
import ENUM_PROVIDER_PATIENT_DOCUMENT_DELIVERY_TYPE from '~/apollo/queries/metadata/enumProviderPatientDocumentDeliveryType'
import ENUM_PROVIDER_PATIENT_DOCUMENT_STATUS from '~/apollo/queries/metadata/enumProviderPatientDocumentStatus'
import ENUM_PROVIDER_PATIENTS_TYPES_QUERY from '~/apollo/queries/metadata/enumProviderPatientStatus'
import ENUM_PROVIDER_QUALIFIED_PATIENT_ACTION from '~/apollo/queries/metadata/enumProviderQualifiedPatientAction'
import ENUM_PROVIDER_WIDGET_ICON_SIZE from '~/apollo/queries/metadata/enumProviderWidgetIconSize'
import ENUM_SUBSCRIPTION_QUANTITY_TYPES_QUERY from '~/apollo/queries/metadata/enumSubscriptionQuantityTypes'
import ENUM_SUBSCRIPTION_TYPES_QUERY from '~/apollo/queries/metadata/enumSubscriptionTypes'
import ENUM_USER_ROLES_QUERY from '~/apollo/queries/metadata/enumUserRoles'
import ENUM_USER_SETTINGS_BENEFITS_VIEW from '~/apollo/queries/metadata/enumUserSettingsBenefitsView'
import ENUM_VOB_STATUS_TYPES_QUERY from '~/apollo/queries/metadata/enumVOBStatusTypes'
import ENUM_WR_VOB_BENEFIT_STATUS_QUERY from '~/apollo/queries/metadata/enumWrVobCoverageBenefitStatus'
import ENUM_WR_VOB_COVERAGE_BENEFIT_LIMIT_AUTHORIZATION_REQUIRED_QUERY from '~/apollo/queries/metadata/enumWrVobCoverageBenefitLimitAuthorizationRequired'
import ENUM_WR_VOB_COVERAGE_BENEFIT_LIMIT_CATEGORY_QUERY from '~/apollo/queries/metadata/enumWrVobCoverageBenefitLimitBenefitCategory'
import ENUM_WR_VOB_COVERAGE_BENEFIT_LIMIT_NETWORK_STATUS_QUERY from '~/apollo/queries/metadata/enumWrVobCoverageBenefitLimitNetworkStatus'
import ENUM_WR_VOB_COVERAGE_BENEFIT_LIMIT_PERIOD_TYPE_QUERY from '~/apollo/queries/metadata/enumWrVobCoverageBenefitLimitTimePeriodType'
import ENUM_WR_VOB_COVERAGE_BENEFIT_LIMIT_QUANTITY_TYPE_QUERY from '~/apollo/queries/metadata/enumWrVobCoverageBenefitLimitQuantityType'
import ENUM_WR_VOB_DEPENDENT_RELATIONSHIP_QUERY from '~/apollo/queries/metadata/enumWrVobDependentRelationship'
import ENUM_WR_VOB_FOLLOW_UP_ACTION_QUERY from '~/apollo/queries/metadata/enumWrVobFollowUpAction'
import ENUM_WR_VOB_PLAN_TYPES_QUERY from '~/apollo/queries/metadata/enumWrVobPlanType'
import ENUM_WR_VOB_POLICY_STATUS_QUERY from '~/apollo/queries/metadata/enumWrVobPolicyStatus'
import ENUM_WR_VOB_PLACE_OF_SERVICE_QUERY from '~/apollo/queries/metadata/enumWrVobPlaceOfService'
import ENUM_WR_VOB_QUANTITY_QUERY from '~/apollo/queries/metadata/enumWrVobQuantity'
import ENUM_WR_VOB_TIME_PERIOD_QUALIFIER_QUERY from '~/apollo/queries/metadata/enumWrVobTimePeriodQualifier'
import ENUM_WR_VOB_REJECT_REASON_QUERY from '~/apollo/queries/metadata/enumWrVobRejectReason'
import ENUM_WR_VOB_STATUS_TYPES_QUERY from '~/apollo/queries/metadata/enumWrVOBStatusTypes'
import INDUSTRY_ATTRIBUTES_QUERY from '~/apollo/queries/metadata/industryAttributes'
import INSURANCE_COMPANIES_QUERY from '~/apollo/queries/common/insuranceCompanies'
import INVOICE_SERVICE_TYPES_QUERY from '~/apollo/queries/metadata/invoiceServiceTypes'
import ISSUES_QUERY from '~/apollo/queries/metadata/issues'
import MIN_MOBILE_VERSION_QUERY from '~/apollo/queries/metadata/minimumMobileVersionRequired'
import PATIENT_ARCHIVE_REASONS_QUERY from '~/apollo/queries/metadata/patientArchiveReasons'
import PATIENT_BUDGETS_QUERY from '~/apollo/queries/metadata/patientBudgets'
import PATIENT_PAYMENT_METHODS_QUERY from '~/apollo/queries/metadata/patientPaymentMethods'
import PAYMENT_PLANS_QUERY from '~/apollo/queries/metadata/paymentPlans'
import PROVIDER_NOTIFICATION_CHANNELS from '~/apollo/queries/metadata/ProviderNotificationChannels'
import STANDARD_LEVELS_OF_CARE_QUERY from '~/apollo/queries/metadata/standardLevelsOfCare'
import STATES_QUERY from '~/apollo/queries/metadata/states'
import STRIPE_KEY_QUERY from '~/apollo/queries/metadata/stripeKey'
import TIMEZONES_QUERY from '~/apollo/queries/metadata/timezones'
import VOB_QUERY from '~/apollo/queries/metadata/vob'

export default {
  data() {
    const ret = {}
    if (this.$options.prefetchMetadata) {
      for (const mtype of this.$options.prefetchMetadata) {
        ret[mtype] = []
      }
    }
    return ret
  },
  metadata: {
    getStates(apollo) {
      apollo.addSmartQuery('states', {
        query: STATES_QUERY,
        update(data) {
          if (!data.metadata || !data.metadata.global) {
            return []
          }
          return data.metadata.global.states
        },
        error(e) {
          throw new Error('Cannot load metadata')
        },
      })
    },
    getTimezones(apollo) {
      apollo.addSmartQuery('timezones', {
        query: TIMEZONES_QUERY,
        update(data) {
          if (!data.metadata || !data.metadata.global) {
            throw new Error('Cannot load metadata')
          }
          return data.metadata.global.timezones
        },
      })
    },
    getAws(apollo) {
      apollo.addSmartQuery('aws', {
        query: AWS_QUERY,
        update(data) {
          if (!data.metadata || !data.metadata.global) {
            throw new Error('Cannot load metadata')
          }
          return data.metadata.global.aws
        },
      })
    },
    getAwsPrivate(apollo) {
      apollo.addSmartQuery('awsPrivate', {
        query: AWS_PRIVATE_QUERY,
        update(data) {
          if (!data.metadata || !data.metadata.global) {
            throw new Error('Cannot load metadata')
          }
          return data.metadata.global.awsPrivate
        },
      })
    },
    getStripeKey(apollo) {
      apollo.addSmartQuery('stripeKey', {
        query: STRIPE_KEY_QUERY,
        update(data) {
          if (!data.metadata || !data.metadata.global) {
            throw new Error('Cannot load metadata')
          }
          return data.metadata.global.stripeKey
        },
      })
    },
    getMinimumMobileVersionRequired(apollo) {
      apollo.addSmartQuery('minimumMobileVersionRequired', {
        query: MIN_MOBILE_VERSION_QUERY,
        update(data) {
          if (!data.metadata || !data.metadata.global) {
            throw new Error('Cannot load metadata')
          }
          return data.metadata.global.minimumMobileVersionRequired
        },
      })
    },
    getInsuranceCompanies(apollo) {
      apollo.addSmartQuery('insuranceCompanies', {
        query: INSURANCE_COMPANIES_QUERY,
        variables: {
          first: 5000,
          hasWaystar: true,
          orderBy: { field: 'NAME' },
        },
        update(data) {
          if (!data.insuranceCompanies) {
            throw new Error('Cannot load metadata')
          }

          const insurances = data.insuranceCompanies.edges.map((i) => i.node)
          return insurances.map((i) => {
            if (i.name.indexOf('(') !== -1 && i.name.indexOf(')') === -1) {
              i.name = i.name + ')'
            }

            return i
          })
        },
      })
    },
    getWaystarInsuranceCompanies(apollo) {
      apollo.addSmartQuery('waystarInsuranceCompanies', {
        query: INSURANCE_COMPANIES_QUERY,
        variables: {
          first: 5000,
          hasWaystar: true,
          orderBy: { field: 'NAME' },
        },
        update(data) {
          if (!data.insuranceCompanies) {
            throw new Error('Cannot load metadata')
          }
          return data.insuranceCompanies.edges.map((i) => i.node)
        },
      })
    },
    getEnumInsurancePlanTypes(apollo) {
      apollo.addSmartQuery('enumInsurancePlanTypes', {
        query: ENUM_INSURANCE_PLAN_TYPES_QUERY,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getInvoiceServiceTypes(apollo) {
      apollo.addSmartQuery('invoiceServiceTypes', {
        query: INVOICE_SERVICE_TYPES_QUERY,
        update(data) {
          if (!data.metadata || !data.metadata.invoice) {
            throw new Error('Cannot load metadata')
          }
          return data.metadata.invoice.serviceTypes
        },
      })
    },
    getPatientPaymentMethods(apollo) {
      apollo.addSmartQuery('patientPaymentMethods', {
        query: PATIENT_PAYMENT_METHODS_QUERY,
        update(data) {
          if (!data.metadata || !data.metadata.patient) {
            throw new Error('Cannot load metadata')
          }
          const paymentMethods = data.metadata.patient.paymentMethods.map(
            (pm) => ({
              ...pm,
              position:
                paymentMethodOptions.find((pmo) => pmo.value === pm.value)
                  ?.position || 100,
            }),
          )
          return paymentMethods.sort((a, b) => {
            return a.position === b.position
              ? 0
              : a.position > b.position
              ? 1
              : -1
          })
        },
      })
    },
    getPatientBudgets(apollo) {
      apollo.addSmartQuery('patientBudgets', {
        query: PATIENT_BUDGETS_QUERY,
        update(data) {
          if (!data.metadata || !data.metadata.patient) {
            throw new Error('Cannot load metadata')
          }
          return data.metadata.patient.budgets
        },
      })
    },
    getStandardLevelsOfCare(apollo) {
      apollo.addSmartQuery('standardLevelsOfCare', {
        query: STANDARD_LEVELS_OF_CARE_QUERY,
        update(data) {
          if (!data.metadata || !data.metadata.provider) {
            return []
          }
          return data.metadata.provider.standardLevelsOfCare
        },
        error(e) {
          throw new Error('Cannot load metadata')
        },
      })
    },
    getProviderNotificationChannels(apollo) {
      apollo.addSmartQuery('providerNotificationChannels', {
        query: PROVIDER_NOTIFICATION_CHANNELS,
        update(data) {
          if (!data.metadata || !data.metadata.provider) {
            throw new Error('Cannot load metadata')
          }
          return data.metadata.provider.notificationChannels
        },
      })
    },
    getIndustryAttributesByCategory(apollo, targetVar, category) {
      apollo.addSmartQuery(targetVar, {
        query: INDUSTRY_ATTRIBUTES_QUERY,
        update(data) {
          if (!data.metadata || !data.metadata.provider) {
            throw new Error('Cannot load metadata')
          }
          return data.metadata.provider.industryAttributes.filter(
            (x) => x.category === category,
          )
        },
      })
    },
    getLevelsOfCare(apollo) {
      this.getIndustryAttributesByCategory(
        apollo,
        'levelsOfCare',
        'levels_of_care',
      )
    },
    getClinicalServices(apollo) {
      this.getIndustryAttributesByCategory(
        apollo,
        'clinicalServices',
        'clinical_service',
      )
    },
    getAmenitiesAndActivities(apollo) {
      this.getIndustryAttributesByCategory(
        apollo,
        'amenitiesAndActivities',
        'amenity_activity',
      )
    },
    getSettings(apollo) {
      this.getIndustryAttributesByCategory(apollo, 'settings', 'setting')
    },
    getProgramTypeAttributes(apollo) {
      this.getIndustryAttributesByCategory(
        apollo,
        'programTypeAttributes',
        'program_type',
      )
    },
    getAccreditations(apollo) {
      return this.getIndustryAttributesByCategory(
        apollo,
        'accreditations',
        'accreditations',
      )
    },
    getIssues(apollo) {
      apollo.addSmartQuery('issues', {
        query: ISSUES_QUERY,
      })
    },
    getPatientArchiveReasons(apollo) {
      apollo.addSmartQuery('patientArchiveReasons', {
        query: PATIENT_ARCHIVE_REASONS_QUERY,
        update(data) {
          if (!data.metadata || !data.metadata.patient) {
            throw new Error('Cannot load metadata')
          }
          return data.metadata.patient.archiveReasons
        },
      })
    },
    getPaymentPlans(apollo) {
      // TODO check all the places where this is being used, that it is still compatible
      apollo.addSmartQuery('paymentPlans', {
        query: PAYMENT_PLANS_QUERY,
        variables: {
          type: 'standard',
        },
        update(data) {
          if (!data.paymentPlans || !data.paymentPlans.edges) {
            throw new Error('Cannot load payment plans')
          }

          return data.paymentPlans.edges.map((i) => i.node)
        },
      })
    },
    getVobMetadata(apollo) {
      apollo.addSmartQuery('vobMetadata', {
        query: VOB_QUERY,
        update(data) {
          if (!data.metadata || !data.metadata.vob) {
            throw new Error('Cannot load metadata')
          }
          return data.metadata.vob
        },
      })
    },
    getEnumNetworkTypes(apollo) {
      apollo.addSmartQuery('enumNetworkTypes', {
        query: ENUM_NETWORK_TYPES_QUERY,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumUserRoles(apollo) {
      apollo.addSmartQuery('enumUserRoles', {
        query: ENUM_USER_ROLES_QUERY,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumUserSettingsBenefitsView(apollo) {
      apollo.addSmartQuery('enumUserSettingsBenefitsView', {
        query: ENUM_USER_SETTINGS_BENEFITS_VIEW,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumOrganizationUserRoles(apollo) {
      apollo.addSmartQuery('enumOrganizationUserRoles', {
        query: ENUM_ORGANIZATION_USER_ROLES_QUERY,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumOrganizationTypes(apollo) {
      apollo.addSmartQuery('enumOrganizationTypes', {
        query: ENUM_ORGANIZATION_TYPES_QUERY,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumOrganizationMembershipTypes(apollo) {
      apollo.addSmartQuery('enumOrganizationMembershipTypes', {
        query: ENUM_ORGANIZATION_MEMBERSHIP_TYPES_QUERY,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumProviderGenderTypes(apollo) {
      apollo.addSmartQuery('enumProviderGenderTypes', {
        query: ENUM_PROVIDER_GENDER_TYPES_QUERY,
        update(data) {
          if (!data && data.__type) {
            throw new Error('Cannot load metadata genders search')
          }
          return data.__type?.enumValues
        },
      })
    },
    getEnumCalendarProviders(apollo) {
      apollo.addSmartQuery('enumCalendarProviders', {
        query: ENUM_CALENDAR_PROVIDERS_QUERY,
        update(data) {
          if (!data && data.__type) {
            throw new Error('Cannot load metadata calendar providers search')
          }
          return data.__type?.enumValues
        },
      })
    },
    getEnumProviderPatientTypes(apollo) {
      apollo.addSmartQuery('enumProviderPatientTypes', {
        query: ENUM_PROVIDER_PATIENTS_TYPES_QUERY,
        update(data) {
          if (!data && data.__type) {
            throw new Error('Cannot load metadata provider types')
          }
          return data.__type?.enumValues
        },
      })
    },
    getEnumPatientSource(apollo) {
      apollo.addSmartQuery('enumPatientSource', {
        query: ENUM_PATIENT_SOURCE_TYPES_QUERY,
        update(data) {
          if (!data && data.__type) {
            throw new Error('Cannot load metadata patient source')
          }
          return data.__type?.enumValues
        },
      })
    },
    getEnumProviderDocumentstatus(apollo) {
      apollo.addSmartQuery('enumProviderDocumentstatus', {
        query: ENUM_PROVIDER_DOCUMENTS_STATUS,
        update(data) {
          if (!data && data.__type) {
            throw new Error('Cannot load metadata patient source')
          }
          return data.__type?.enumValues
        },
      })
    },
    getEnumGenderTypes(apollo) {
      apollo.addSmartQuery('enumGenderTypes', {
        query: ENUM_GENDER_TYPES_QUERY,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumVobStatusTypes(apollo) {
      apollo.addSmartQuery('enumVobStatusTypes', {
        query: ENUM_VOB_STATUS_TYPES_QUERY,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumProviderMassMessagePatientGroup(apollo) {
      apollo.addSmartQuery('enumProviderMassMessagePatientGroup', {
        query: ENUM_PROVIDER_MASS_MESSAGE_PATIENT_GROUP_QUERY,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumProviderMassMessageStatus(apollo) {
      apollo.addSmartQuery('enumProviderMassMessageStatus', {
        query: ENUM_PROVIDER_MASS_MESSAGE_STATUS_QUERY,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumWrVobStatusTypes(apollo) {
      apollo.addSmartQuery('enumWrVobStatusTypes', {
        query: ENUM_WR_VOB_STATUS_TYPES_QUERY,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumWrVobCoverageBenefitLimitTimePeriodType(apollo) {
      apollo.addSmartQuery('enumWrVobCoverageBenefitLimitTimePeriodType', {
        query: ENUM_WR_VOB_COVERAGE_BENEFIT_LIMIT_PERIOD_TYPE_QUERY,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumWrVobCoverageBenefitLimitQuantityType(apollo) {
      apollo.addSmartQuery('enumWrVobCoverageBenefitLimitQuantityType', {
        query: ENUM_WR_VOB_COVERAGE_BENEFIT_LIMIT_QUANTITY_TYPE_QUERY,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumWrVobCoverageBenefitLimitNetworkStatus(apollo) {
      apollo.addSmartQuery('enumWrVobCoverageBenefitLimitNetworkStatus', {
        query: ENUM_WR_VOB_COVERAGE_BENEFIT_LIMIT_NETWORK_STATUS_QUERY,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumWrVobCoverageBenefitLimitBenefitCategory(apollo) {
      apollo.addSmartQuery('enumWrVobCoverageBenefitLimitBenefitCategory', {
        query: ENUM_WR_VOB_COVERAGE_BENEFIT_LIMIT_CATEGORY_QUERY,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumWrVobCoverageBenefitLimitAuthorizationRequired(apollo) {
      apollo.addSmartQuery(
        'enumWrVobCoverageBenefitLimitAuthorizationRequired',
        {
          query:
            ENUM_WR_VOB_COVERAGE_BENEFIT_LIMIT_AUTHORIZATION_REQUIRED_QUERY,
          update(data) {
            return data.__type?.enumValues
          },
        },
      )
    },
    getEnumWrVobPlanType(apollo) {
      apollo.addSmartQuery('enumWrVobPlanType', {
        query: ENUM_WR_VOB_PLAN_TYPES_QUERY,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumWrVobRejectReason(apollo) {
      apollo.addSmartQuery('enumWrVobRejectReason', {
        query: ENUM_WR_VOB_REJECT_REASON_QUERY,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumWrVobFollowUpAction(apollo) {
      apollo.addSmartQuery('enumWrVobFollowUpAction', {
        query: ENUM_WR_VOB_FOLLOW_UP_ACTION_QUERY,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumWrVobCoverageBenefitStatus(apollo) {
      apollo.addSmartQuery('enumWrVobCoverageBenefitStatus', {
        query: ENUM_WR_VOB_BENEFIT_STATUS_QUERY,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumWrVobDependentRelationship(apollo) {
      apollo.addSmartQuery('enumWrVobDependentRelationship', {
        query: ENUM_WR_VOB_DEPENDENT_RELATIONSHIP_QUERY,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumWrVobPolicyStatus(apollo) {
      apollo.addSmartQuery('enumWrVobPolicyStatus', {
        query: ENUM_WR_VOB_POLICY_STATUS_QUERY,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumWrVobPlaceOfService(apollo) {
      apollo.addSmartQuery('enumWrVobPlaceOfService', {
        query: ENUM_WR_VOB_PLACE_OF_SERVICE_QUERY,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumWrVobQuantity(apollo) {
      apollo.addSmartQuery('enumWrVobQuantity', {
        query: ENUM_WR_VOB_QUANTITY_QUERY,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumWrVobTimePeriodQualifier(apollo) {
      apollo.addSmartQuery('enumWrVobTimePeriodQualifier', {
        query: ENUM_WR_VOB_TIME_PERIOD_QUALIFIER_QUERY,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumConversationMessageAction(apollo) {
      apollo.addSmartQuery('enumConversationMessageAction', {
        query: CONVERSATION_MESSAGE_ACTION_QUERY,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumProviderPatientDocumentDeliveryType(apollo) {
      apollo.addSmartQuery('enumProviderPatientDocumentDeliveryType', {
        query: ENUM_PROVIDER_PATIENT_DOCUMENT_DELIVERY_TYPE,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumProviderPatientDocumentStatus(apollo) {
      apollo.addSmartQuery('enumProviderPatientDocumentStatus', {
        query: ENUM_PROVIDER_PATIENT_DOCUMENT_STATUS,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumOrganizationUserRole(apollo) {
      apollo.addSmartQuery('enumOrganizationUserRole', {
        query: ENUM_ORGANIZATION_USER_ROLE_TYPES_QUERY,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumSubscriptionTypes(apollo) {
      apollo.addSmartQuery('enumSubscriptionTypes', {
        query: ENUM_SUBSCRIPTION_TYPES_QUERY,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumSubscriptionQuantityTypes(apollo) {
      apollo.addSmartQuery('enumSubscriptionQuantityTypes', {
        query: ENUM_SUBSCRIPTION_QUANTITY_TYPES_QUERY,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumConversationMessageMediaTypes(apollo) {
      apollo.addSmartQuery('enumConversationMessageMediaTypes', {
        query: ENUM_CONVERSATION_MESSAGE_MEDIA_TYPES_QUERY,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumExportFormat(apollo) {
      apollo.addSmartQuery('enumExportFormat', {
        query: ENUM_EXPORT_FORMAT_QUERY,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumClinicianBillType(apollo) {
      apollo.addSmartQuery('enumClinicianBillType', {
        query: ENUM_CLINICIAN_BILL_TYPE,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumProviderQualifiedPatientAction(apollo) {
      apollo.addSmartQuery('enumProviderQualifiedPatientAction', {
        query: ENUM_PROVIDER_QUALIFIED_PATIENT_ACTION,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumProviderWidgetIconSize(apollo) {
      apollo.addSmartQuery('enumProviderWidgetIconSize', {
        query: ENUM_PROVIDER_WIDGET_ICON_SIZE,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
    getEnumPatientPaymentMethod(apollo) {
      apollo.addSmartQuery('enumPatientPaymentMethod', {
        query: ENUM_PATIENT_PAYMENT_METHOD,
        update(data) {
          return data.__type?.enumValues
        },
      })
    },
  },
  created() {
    if (this.$options.prefetchMetadata) {
      for (const mtype of this.$options.prefetchMetadata) {
        this.$options.metadata[
          `get${mtype.charAt(0).toUpperCase()}${mtype.substring(1)}`
        ](this.$apollo)
      }
    }
  },
}
